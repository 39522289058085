$tabMaxSize: 991px;
$tabMinSize: 768px;
// 320px
// 480px
// 600px
// 768px
// 900px
// 1024px
// 1200px
@mixin max-xl {
  @media (max-width: 1399px) {
    @content;
  }
}
@mixin max-lg {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin max-md {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin max-sm {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin max-xs {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin min-xs {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin min-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin min-xl {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin min-xxl {
  @media (min-width: 1400px) {
    @content;
  }
}
